const defaultClick = {
    click: false
}


export const clickPageReducer = (state = defaultClick, action ) => {
    switch(action.type){
        case "CLICK_PAGE_TRUE":
            return { ...state, click: true }
        case "CLICK_PAGE_FALSE":
            return { ...state, click: false }
        default: return state
    }
}