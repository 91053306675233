const defaultPermission = {
    permission: {}
}

export const permissionReducer = (state = defaultPermission, action) => {
    switch(action.type){
        case "SET_PERMISSION":
            return { ...state, permission: action.payload}
        default: 
            return state
    }
}