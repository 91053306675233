import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchingData } from "../../Fetch"
import { LoaderWindow } from '../../Components/LoaderWindow'
import './styles.sass'
import { Pagination } from "../Pagination"

export const FacebookSearchResultsPage = () => {
    
    const dispatch = useDispatch()

    const tableHeader = ["NAME", "JOBS", "COMPANY", "DATA"]

    const [result, setResult] = useState([])

    const token = useSelector(state => state.accessTokenReducer.token)
    const currentPosition = useSelector(state => state.currentPositionReducer.currentPosition )
    const IdComp = useSelector(state => state.companyIdReducer.IdComp)
    const IdJob = useSelector(state => state.jobIdReducer.IdJob)  
    const pageNum = useSelector(state => state.pagesReducer.pageNum)
    const changingFlag = useSelector(state => state.arrayChangingFlagReducer.flag)
    const clickPage = useSelector(state => state.clickPageReducer.click)


    const fetchingUsers = async (url) => {
        if(changingFlag){
            dispatch({type:"DEFAULT_PAGE"})
        }
        try{
            const REQUESTSOPTIONS = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(
                    {
                        "Companies": IdComp,

                        "JobTitles": IdJob,
                        
                        "SearchId": changingFlag ? undefined : result.id || undefined,
                        
                        "PageNumber": pageNum,
                        
                        "OnlyCurrent": currentPosition
                    }
                )
            }
            
            const res = await fetchingData(url, REQUESTSOPTIONS)
                setResult(res)
                // console.log(res);
                dispatch({type:'CLICK_PAGE_FALSE'}) 
                dispatch({type:'SET_CHANGING_FLAG_FALSE'}) 
        }catch(err){
            console.log(err);}
        }

    useEffect(() => {
        fetchingUsers(process.env.REACT_APP_PEOPLE)
    }, [currentPosition, IdComp, IdJob, pageNum])
    
    return(
        <div 
            className="facebook-results-wrapper"
        >
        
            <div className="facebook-results-wrapper__header-title">
                Facebook Search
            </div>

            <div 
                className="facebook-results-wrapper__table-wrapper"
            >
                
                <div className="facebook-results-wrapper__table-wrapper__table-header">
                    {tableHeader.map((elem, headerIndex) => {
                        return(
                            <div key={headerIndex}
                                className="facebook-results-wrapper__table-wrapper__table-header__title"
                            >
                                {elem}
                            </div>
                        )
                    })}
                </div>

                <div className="facebook-results-wrapper__table-wrapper__results-wrapper">
                    {result.length === 0 || clickPage || changingFlag ?
                        <LoaderWindow/>
                        :  
                    result.profiles.map((profile, index) => {
                        return(
                            <div key={index}
                                className="facebook-results-wrapper__table-wrapper__results-wrapper__strings"
                            >
                                
                                <div className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__nickname">

                                    <img alt='avatar' 
                                        referrerPolicy="no-referrer"
                                        src={profile.avatarUrl} 
                                        className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__nickname__avatar"/>
                                                                
                                    <a href={profile.profileUrl} style={{textDecoration: "none", color: "#000000"}}>
                                        {profile.fullName || profile.nickname}        
                                    </a>
                                </div>

                                <div className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__inforamtion">
                                    {profile.jobInfo.map((elem, jobIndex) => {
                                        return(
                                            <div key={jobIndex}
                                                className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__inforamtion__title-wrapper"
                                            >
                                                <div className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__inforamtion__title-wrapper__title jobs">
                                                    {elem.jobTitle}
                                                </div>

                                                <div className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__inforamtion__title-wrapper__title companies">
                                                    <a href={elem.companyUrl} style={{textDecoration: "none", color: "#000000"}}>
                                                        {elem.company}
                                                    </a>
                                                </div>
                                                
                                                <div className="facebook-results-wrapper__table-wrapper__results-wrapper__strings__inforamtion__title-wrapper__title dates">
                                                    {elem.startDateText} - {elem.endDateText}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
            
            <div className="facebook-results-wrapper__pagination">
                    <Pagination pagesCount={result.pagesCount}/>
            </div>

        </div>
    )
}