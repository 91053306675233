import React from 'react'
import {ButtonFade, ButtonBright} from '../../Icons/icons'
import { useDispatch, useSelector } from 'react-redux'
import './styles.sass'

export const Pagination = ({pagesCount}) => {

    const dispatch = useDispatch()
    const pageNum = useSelector(state => state.pagesReducer.pageNum)
    
    const delPage = () => {
        if (pageNum !== 1){
            dispatch({type:'CLICK_PAGE_TRUE'})
            dispatch({type:'DECREMENT_PAGE'})}
      }
      const addPage = () => {
        if (pageNum !== pagesCount) {
            dispatch({type:'CLICK_PAGE_TRUE'})
            dispatch({type:'INCREMENT_PAGE'})} 
      }
    
    return (
     ( (pagesCount !== undefined ) && (pagesCount !== 1 ) && (pagesCount !== 0 ) ) 
            &&
        <div className='pagination-wrapper'>

            <div style={{ display: 'flex', gap: '3px' }}>
                
                <div className='pagination-wrapper__left-button'
                    onClick={() => delPage()}>
                    {pageNum === 1 ?
                        <ButtonFade />
                    :
                    <div style={{ transform: 'rotate(180deg)', paddingTop:'0px' }}>
                        <ButtonBright />
                    </div>}
                </div>

                <div className='pagination-wrapper__right-button'
                    onClick={() => addPage()}>
                    {pageNum === pagesCount ?
                    <div style={{ transform: 'rotate(180deg)' }}>
                        <ButtonFade />
                    </div>
                    :
                        <ButtonBright />}
                </div>
            </div>

            <div className='pagination-wrapper__quantity'>{pageNum || 1}-{pagesCount}</div>
            
      </div> 
    )
}