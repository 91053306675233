const defaultJobId = {
    IdJob: [],
    TitleJob: []
}

export const jobIdReducer = (state = defaultJobId, action ) => {
    switch(action.type){
        case "ADD_JOB_ID": 
            return {
                ...state, 
                IdJob: [...state.IdJob, action.id ],
                TitleJob: [...state.TitleJob, action.title ]
            }
        case "DELETE_JOB_ID": 
            return{  
                ...state,
                IdJob : state.IdJob.slice(0, action.index).concat(state.IdJob.slice(action.index+1)) ,
                TitleJob : state.TitleJob.slice(0, action.index).concat(state.TitleJob.slice(action.index+1))  
            }
        default:
            return state
    }
}