import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AllPayments } from '../../Components/AllPayments'
import { Confirmation } from '../../Components/Confirmation'
import { VerifyReceipts } from '../../Components/VerifyReceipts'
import './styles.sass'

export const FinancesContainer = () => {

    const permission = useSelector(state => state.permissionReducer.permission)

    const [choseFinanceNavbar, setChoseFinanceNavbar] = useState(0)

    useEffect(() => {
        setChoseFinanceNavbar(JSON.parse(window.localStorage.getItem('choseFinanceNavbar')))
    },[])
    
    useEffect(() => {
       window.localStorage.setItem('choseFinanceNavbar', choseFinanceNavbar)
    },[choseFinanceNavbar])

    const teamOptions = [
        {name: 'Verify receipts', data: <VerifyReceipts/>},
        {name: 'All Payments', data: <AllPayments/>},
        {name: 'Confirmation', data: <Confirmation/>},
    ]
    
    return (
        <div 
            className='finances-container-wrapper'
        >
            
            <div 
                className='finances-container-wrapper__navbar-wrapper'
            >

                <div 
                    className='finances-container-wrapper__navbar-wrapper__team-wrapper'
                >
                    
                    <div className='finances-container-wrapper__navbar-wrapper__team-wrapper__title'>
                        Team Payments
                    </div>
                    
                    <div className='finances-container-wrapper__navbar-wrapper__team-wrapper__tabs'>

                        {teamOptions.map((elem, index) => {
                            return(
                                <div key={index}
                                style={
                                    (index === 0 && !permission.financesVerifyReceiptsView)
                                    ||
                                    (index === 1 && !permission.financesAllPaymentsView)
                                    ||
                                    (index === 2 && !permission.financesConfirmView) 
                                    ? 
                                    { width: 0, height: 0, overflow: "hidden" }
                                    :
                                    {}
                                } 
                                className={
                                    choseFinanceNavbar === index ? 
                                    'finances-container-wrapper__navbar-wrapper__team-wrapper__tabs__title chose'
                                    :
                                    'finances-container-wrapper__navbar-wrapper__team-wrapper__tabs__title'}
                                    onClick={() => setChoseFinanceNavbar(index)}    
                                >
                                    {elem.name}
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
            
            {
            choseFinanceNavbar !== null
                 &&
            <div 
            className='finances-container-wrapper__data-wrapper'
                >
                <div 
                    className='finances-container-wrapper__data-wrapper__header'
                    >
                    <span>Finances </span><span>{'>'}</span>
                    <span>Team Paymaents</span><span>{'>'}</span>
                    <span style={{color:'black'}}>{teamOptions[choseFinanceNavbar].name}</span>  
                </div>

                <div className='finances-container-wrapper__data-wrapper__table-wrapper'>
                        {teamOptions[choseFinanceNavbar].data}
                </div>
            </div>
            }
        </div>
    )
}