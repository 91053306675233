import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { fetchingData } from '../../Fetch'
import { PurseToPersonType12Icon, PurseFromPersonType12Icon,
    PurseToPersonType34Icon, ReceiptIcon } from '../../Icons/icons'
import { LoaderWindow } from '../../Components/LoaderWindow/index'
import './styles.sass'

export const ProfilePayments = () => {

    const [table, setTable] = useState([])
    const [receipt, setReceipt] = useState([])
    
    const array = []

    const [link, setLink] = useState([])

    const token = useSelector(state => state.accessTokenReducer.token)

    const headers = ["FROM", "TO","PAYMENT PERIOD", "AMOUNT", "STATUS", "RECEIPT"]

    const receiptHeaders = ["DATE OF SALE", "SERVICE NAME", "PRICE", "TIN", "NAME OF COMPANY", "FIELD FOR SENDING A LINK TO A CHECK"]
    

    function handleLinkArray(length){
        for (let i = 0; i < length; i++){
            array.push(``)
        }
        setLink(array)
    }

    function updatingArray(value, index){
        setLink([...link.slice(0, index), value, ...link.slice(index+1)])
    }


    useEffect(() => {      
            
        const fetchingProjects = async(url) => {
            try{     
                const REQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = await fetchingData(url, REQUESTSOPTIONS)   
                setTable(res)
                // console.log(res);
            }catch(err){
                    console.log(err);
                }
            }
        if(token){
            fetchingProjects(process.env.REACT_APP_PAYMENTS)
        }
        
    },[token])
    
    const fetchingProjectsCheck = async(url) => {
        try{     
                const REQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = await fetchingData(url, REQUESTSOPTIONS)
                handleLinkArray(res.length)   
                setReceipt(res)
                // console.log(res);
            }catch(err){
                console.log(err);
            }
        }

    useEffect(() => {          
        if(token){
            fetchingProjectsCheck(process.env.REACT_APP_PAYMENTS_RECEIPT)
        }
    },[token])

    const sendReceiptHandler = async(id, index) => {
        if (link[index].includes('lknpd.nalog.ru')) {

            const REQUESTSOPTIONS = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(
                    {
                        "id": id,
                        "url": link[index],
                    }
                )
            }
            const res = await fetchingData(process.env.REACT_APP_PAYMENTS_RECEIPT + '/' + id, REQUESTSOPTIONS)
            
            if (res) {
                setReceipt([...receipt.slice(0, index), ...receipt.slice(index+1)])
                setLink([...link.slice(0, index), ...link.slice(index+1)])
                // console.log(link);
            }
        }
    }

    return(
        <div
            className='profile-payments-wrapper'
        >
            {((table.length === 0) || (!table) || (receipt.length === 0) || (!receipt)) ? 
                <div style={{position:'absolute', width:'100%', height:'92%'}}>
                    <LoaderWindow/>
                </div>
                    :
                <>
                {receipt 
                    &&
                receipt.length !== 0 
                && 
                    <div
                        className='profile-payments-wrapper__receipt-wrapper'
                    >
                        <div className='profile-payments-wrapper__receipt-wrapper__header'>
                            Payments awaiting check
                        </div>

                        <div className='profile-payments-wrapper__receipt-wrapper__title'>
                            Necessary data for issuing a check
                        </div>

                        <div className='profile-payments-wrapper__receipt-wrapper__receipt-data-header'>
                            {receiptHeaders.map((receiptHeader, receiptHeaderIndex) => {
                                return(
                                    <div key={receiptHeaderIndex}
                                    className="profile-payments-wrapper__receipt-wrapper__receipt-data-header__title">
                                        {receiptHeader}
                                    </div>
                                )
                            })}
                        </div>
                        
                        <div className='profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper'>
                            {receipt.map((receiptData, receiptDataIndex) => {
                                return(
                                    <div key={receiptDataIndex}
                                        className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data"
                                    >
                                        <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title">
                                            {receiptData.paymentDate.toString().slice(8,10)}
                                            .
                                            {receiptData.paymentDate.toString().slice(5,7)}
                                            .
                                            {receiptData.paymentDate.toString().slice(0,4)}
                                        </div>

                                        <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title">
                                            {/* {receiptData.} */}
                                        </div>

                                        <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title">
                                            {receiptData.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                        </div>

                                        <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title">
                                            {receiptData.companyPaymentAccountDetails.itn}
                                        </div >

                                        <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title">
                                            {receiptData.companyPaymentAccountDetails.title}
                                        </div>

                                        <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title">
                                           
                                            <input type="text" 
                                                placeholder='link to check' 
                                                value={link[receiptDataIndex]}
                                                onChange={e => updatingArray(e.target.value, receiptDataIndex)}
                                            /> 
                                            
                                            <div className="profile-payments-wrapper__receipt-wrapper__receipt-data-wrapper__receipt-data__title__button"
                                                onClick={() => sendReceiptHandler(receiptData.id, receiptDataIndex)}
                                            >
                                                Send
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>

                    </div>
                }

                    <div className='profile-payments-wrapper__table-wrapper'>

                        <div className='profile-payments-wrapper__table-wrapper__header-wrapper'>
                            {headers.map((elem, index) => {
                                return(
                                    <div key={index} 
                                        className='profile-payments-wrapper__table-wrapper__header-wrapper__headers'>
                                        <div 
                                            className='profile-payments-wrapper__table-wrapper__header-wrapper__headers__title'>
                                            {elem}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        
                        <div className='profile-payments-wrapper__table-wrapper__table-data'>
                
                            {
                                
                            table.map((elem, index) => {
                                return(
                                    <div key={index}
                                        className='profile-payments-wrapper__table-wrapper__table-data__data-line'
                                    >
                                        <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__from'>
                                            {elem.paymentAccountFrom.type === ( 1 || 2 ) ? 
                                                <div  className='profile-payments-wrapper__table-wrapper__table-data__data-line__to__button'>
                                                <PurseToPersonType12Icon/>  
                                                </div>
                                                    :
                                                <div  className='profile-payments-wrapper__table-wrapper__table-data__data-line__to__button'>
                                                    <PurseToPersonType34Icon/>
                                                </div>
                                            }
                                            <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__title'>
                                                {elem.paymentAccountFrom.title}
                                        </div>

                                        </div>

                                        <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__to'>
                                            {elem.paymentAccountFrom.type === ( 1 || 2 ) ? 
                                                <div  className='profile-payments-wrapper__table-wrapper__table-data__data-line__to__button'>
                                                    <PurseFromPersonType12Icon/> 
                                                </div>
                                                    :
                                                <div  className='profile-payments-wrapper__table-wrapper__table-data__data-line__to__button'>
                                                    <PurseToPersonType34Icon/>
                                                </div>
                                            }
                                            <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__title'>
                                                {elem.paymentAccountTo.title}
                                        </div>
                            
                                        </div>

                                        <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__payment'>
                                            <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__title'>
                                                {elem.paymentPeriod.title}
                                            </div>
                                        </div>

                                        <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__amount'>
                                            <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__title'>
                                                {elem.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                            </div>
                                        </div>

                                        <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__status'>
                                            <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__title'>
                                                <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__status__background'
                                                style={
                                                    elem.paymentStatus.status === 0 ? {background:'#D9F1FF'} 
                                                        :
                                                    elem.paymentStatus.status === 2 ||  elem.paymentStatus.status === 3 || elem.paymentStatus.status === 5 ? {background:'#FFFBCC'} 
                                                        :
                                                    elem.paymentStatus.status === 1 || elem.paymentStatus.status === 4 || elem.paymentStatus.status === 6 ? {border:' 3px solid #FFFBCC'} 
                                                        : 
                                                    elem.paymentStatus.status === 8 ? {border:' 3px solid #D9FAE5'}
                                                        : 
                                                    elem.paymentStatus.status === 10 ? {border:' 3px solid #FFD6D6'}
                                                        : 
                                                    {border:' 3px solid red'}
                                                    }>
                                                    {elem.paymentStatus.title}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='profile-payments-wrapper__table-wrapper__table-data__data-line__receipt'>
                                            <ReceiptIcon/>
                                        </div>

                                    </div>
                                )
                            })}

                        </div>

                    </div>   
                </>
            }  
        </div>
    )
} 