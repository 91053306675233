const defaultIdComp = {
    IdComp: [], 
    TitleComp: []
}

export const companyIdReducer = (state = defaultIdComp, action ) => {
    switch(action.type){
        case "ADD_COMPANY_ID": 
            return {
                ...state, 
                IdComp: [...state.IdComp, action.id ],
                TitleComp: [...state.TitleComp, action.title ]
            }
        case "DELETE_COMPANY_ID": 
            return {
                ...state,
                IdComp : state.IdComp.slice(0, action.index).concat(state.IdComp.slice(action.index+1)) ,
                TitleComp : state.TitleComp.slice(0, action.index).concat(state.TitleComp.slice(action.index+1)) 
            }
        default:
            return state
    }
}