import React, {useState, useEffect} from 'react'
import { useSelector } from "react-redux"
import { fetchingData } from '../../Fetch'
import { LoaderWindow } from '../LoaderWindow'

import './styles.sass'

export const ConfirmationOfPayment = () => {

    const [result, setResult] = useState([])
    const [confirm, setConfirm] = useState()

    const headers = ["WORKDAYS", "PAYMENT", "PAYOUT INCLUDING TAX"]

    const littleHeaders = ["PROJECTS", "ROLE", "WORK PERIOD", "RATE", "INCOME"]

    const token = useSelector(state => state.accessTokenReducer.token)


    useEffect(() => {
        const fetchingConfirm = async(url) => {
            try{    
                const GETREQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = fetchingData(url, GETREQUESTSOPTIONS)
                console.log(res);
                res.then(data => setResult(data))    
            }catch(err){
                console.log(err);
            }
        }

        const fetchingConfirmPOST = async(url) => {
            try{    
                const POSTREQUESTSOPTIONS = {
                    method: 'POST',
                    headers: { 
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = fetchingData(url + '/' + confirm, POSTREQUESTSOPTIONS)
                console.log(res);
            }catch(err){
                console.log(err);
            }
        }

        if(token){
            fetchingConfirm(process.env.REACT_APP_PROFILE_CONFIRMATION)
        }
        if(token && confirm){
            console.log("Table went")
            setResult([])
            fetchingConfirmPOST(process.env.REACT_APP_PROFILE_CONFIRMATION)
            fetchingConfirm(process.env.REACT_APP_PROFILE_CONFIRMATION)
        }
    },[confirm, token])

  return (
    <div
        className='ConfirmationOfPayment-wrapper'
    >
    {result.length === 0 ?
        <div>
            <LoaderWindow/>
        </div>
            :
        result.map((elem, index) => {
            return(
                <div key={index} 
                    className='ConfirmationOfPayment-wrapper__data-wrapper'
                >
                    <div
                        className='ConfirmationOfPayment-wrapper__data-wrapper__header-line'
                    >  
                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__header-line__title'>
                            {elem.paymentPeriod.title}
                        </div>

                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__header-line__button'
                            onClick={() => setConfirm(elem.id)}
                        >
                            <div className='ConfirmationOfPayment-wrapper__data-wrapper__header-line__button__title'>
                                Confirm Payment
                            </div>
                        </div>

                    </div>

                    <div
                        className='ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo'
                    >
                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo__header'>
                            {headers.map((elem, index) => {
                                return(
                                    <div key={index}
                                    className="ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo__header__title">
                                        {elem} 
                                    </div>
                                )
                            })}
                        </div>
                        
                        <div 
                            className='ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo__data'
                        >
                            
                            <div className='ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo__data__title'>
                                {elem.paymentPeriod.workDays}
                            </div>
                            
                            <div className='ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo__data__title'>
                                {elem.rawAmount}
                            </div>
                            
                            <div className='ConfirmationOfPayment-wrapper__data-wrapper__AboveTableInfo__data__title'>
                                {elem.amount}
                            </div>
                        </div>

                    </div>

                    <div 
                        className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper'
                    >
                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__header'>
                            {littleHeaders.map((elem, headerIndex) => {
                                return(
                                    <div key={headerIndex}
                                    className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__header__title'
                                    >     
                                        {elem}
                                    </div>
                                )
                            })}
                        </div>

                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table'>
                            
                           {elem.projectPaymentDetails.map((info, infoIndex) => {
                               return(
                                    <div key={infoIndex}
                                        className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table__data'
                                    >
                                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table__data__title'>
                                            {info.projectTitle}
                                        </div>

                                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table__data__title'>
                                            {info.projectRole}
                                        </div>
                                        
                                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table__data__title'>
                                            {info.startDate.slice(5, 7)}.{info.startDate.slice(8, 10)}-{info.endDate.slice(5, 7)}.{info.endDate.slice(8, 10)}
                                        </div>
                                        
                                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table__data__title'>
                                            {info.rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                        </div>
                                        
                                        <div className='ConfirmationOfPayment-wrapper__data-wrapper__table-wrapper__table__data__title'>
                                            {info.rawAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                        </div>
                                   </div>
                               )
                            })}
                           
                        </div>

                    </div>

                </div>
            )
        })
    }
    </div>
  )
}
