const defaultLoader = {
    loaderState: false
}

export const loaderReducer = (state = defaultLoader, action) => {
    switch(action.type) {
        case "LOADER_ON":
            return {...state, loaderState: true}

        case "LOADER_OFF":
            return {...state, loaderState: false}

        default:
             return state
    }
}