import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import { rootReducer } from './Redux/rootReducer';
import Auth0ProviderWithHistory from './auth0-provider-with-history'
import './index.sass';



// const store = createStore(rootReducer)

const store =createStore(rootReducer)

ReactDOM.render(
 
 <BrowserRouter>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <App />
      </Provider>  
    </Auth0ProviderWithHistory>
  </BrowserRouter>,

 document.getElementById('root')
);
