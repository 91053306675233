export const ProjectsTabIcon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#3F3E3E"/>
        <path d="M22 10.75H18C14.58 10.75 13.25 9.41999 13.25 5.99999V1.99999C13.25 1.69999 13.43 1.41999 13.71 1.30999C13.99 1.18999 14.31 1.25999 14.53 1.46999L22.53 9.46999C22.74 9.67999 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.80999V5.99999C14.75 8.57999 15.42 9.24999 18 9.24999H20.19L14.75 3.80999Z" fill="#3F3E3E"/>
        <path d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z" fill="#3F3E3E"/>
        <path d="M11 17.75H7C6.59 17.75 6.25 17.41 6.25 17C6.25 16.59 6.59 16.25 7 16.25H11C11.41 16.25 11.75 16.59 11.75 17C11.75 17.41 11.41 17.75 11 17.75Z" fill="#3F3E3E"/>
        </svg>
        
        )
    }
export const FinancesTabIcon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4002 17.4201H10.8902C9.25016 17.4201 7.92016 16.0401 7.92016 14.3401C7.92016 13.9301 8.26016 13.5901 8.67016 13.5901C9.08016 13.5901 9.42016 13.9301 9.42016 14.3401C9.42016 15.2101 10.0802 15.9201 10.8902 15.9201H13.4002C14.0502 15.9201 14.5902 15.3401 14.5902 14.6401C14.5902 13.7701 14.2802 13.6001 13.7702 13.4201L9.74016 12.0001C8.96016 11.7301 7.91016 11.1501 7.91016 9.36008C7.91016 7.82008 9.12016 6.58008 10.6002 6.58008H13.1102C14.7502 6.58008 16.0802 7.96008 16.0802 9.66008C16.0802 10.0701 15.7402 10.4101 15.3302 10.4101C14.9202 10.4101 14.5802 10.0701 14.5802 9.66008C14.5802 8.79008 13.9202 8.08008 13.1102 8.08008H10.6002C9.95016 8.08008 9.41016 8.66008 9.41016 9.36008C9.41016 10.2301 9.72016 10.4001 10.2302 10.5801L14.2602 12.0001C15.0402 12.2701 16.0902 12.8501 16.0902 14.6401C16.0802 16.1701 14.8802 17.4201 13.4002 17.4201Z" fill="#3F3E3E"/>
        <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z" fill="#3F3E3E"/>
        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#3F3E3E"/>
        </svg>  
        )
    }
    
export const FacebookSearchTabIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.75 22.75H10.25V15.5H9.29999C8.71999 15.5 8.25 15.03 8.25 14.45V12.55C8.25 11.97 8.71999 11.5 9.29999 11.5H10.25V9C10.25 6.93 11.93 5.25 14 5.25H16.7C17.28 5.25 17.75 5.71999 17.75 6.29999V8.70001C17.75 9.28001 17.28 9.75 16.7 9.75H14.75V11.5H16.63C16.95 11.5 17.24 11.64 17.44 11.88C17.64 12.12 17.72 12.44 17.66 12.75L17.28 14.65C17.18 15.14 16.75 15.49 16.25 15.49H14.75V22.75ZM11.75 21.25H13.25V14H15.89L16.09 13H13.26V9.29999C13.26 8.71999 13.73 8.25 14.31 8.25H16.26V6.75H14C12.76 6.75 11.75 7.76 11.75 9V13H9.75V14H11.75V21.25Z" fill="#3F3E3E"/>
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#3F3E3E"/>
        </svg>
    )
}
export const ProfileTabIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1205 14.3713C12.1005 14.3713 12.0705 14.3713 12.0505 14.3713C12.0205 14.3713 11.9805 14.3713 11.9505 14.3713C9.68047 14.3018 7.98047 12.5429 7.98047 10.3767C7.98047 8.17066 9.79047 6.37207 12.0105 6.37207C14.2305 6.37207 16.0405 8.17066 16.0405 10.3767C16.0305 12.5529 14.3205 14.3018 12.1505 14.3713C12.1305 14.3713 12.1305 14.3713 12.1205 14.3713ZM12.0005 7.85268C10.6005 7.85268 9.47047 8.98549 9.47047 10.3667C9.47047 11.7281 10.5405 12.8311 11.9005 12.8808C11.9305 12.8708 12.0305 12.8708 12.1305 12.8808C13.4705 12.8112 14.5205 11.7181 14.5305 10.3667C14.5305 8.98549 13.4005 7.85268 12.0005 7.85268Z" fill="#3F3E3E"/>
        <path d="M11.9998 23.533C9.30984 23.533 6.73984 22.5393 4.74984 20.7308C4.56984 20.5718 4.48984 20.3333 4.50984 20.1048C4.63984 18.9223 5.37984 17.8193 6.60984 17.0044C9.58984 15.0369 14.4198 15.0369 17.3898 17.0044C18.6198 17.8292 19.3598 18.9223 19.4898 20.1048C19.5198 20.3433 19.4298 20.5718 19.2498 20.7308C17.2598 22.5393 14.6898 23.533 11.9998 23.533ZM6.07984 19.906C7.73984 21.2873 9.82984 22.0425 11.9998 22.0425C14.1698 22.0425 16.2598 21.2873 17.9198 19.906C17.7398 19.2999 17.2598 18.7136 16.5498 18.2366C14.0898 16.607 9.91984 16.607 7.43984 18.2366C6.72984 18.7136 6.25984 19.2999 6.07984 19.906Z" fill="#3F3E3E"/>
        <path d="M12 23.5334C6.07 23.5334 1.25 18.7438 1.25 12.8512C1.25 6.95855 6.07 2.16895 12 2.16895C17.93 2.16895 22.75 6.95855 22.75 12.8512C22.75 18.7438 17.93 23.5334 12 23.5334ZM12 3.65949C6.9 3.65949 2.75 7.78332 2.75 12.8512C2.75 17.919 6.9 22.0428 12 22.0428C17.1 22.0428 21.25 17.919 21.25 12.8512C21.25 7.78332 17.1 3.65949 12 3.65949Z" fill="#3F3E3E"/>
        </svg>
    )
}

export const LogOutTabIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 21.3525C4.5 21.2436 4.61605 21.0731 4.85714 21.0731H17.7143C19.3675 21.0731 20.7857 19.8485 20.7857 18.2349V4.20601C20.7857 2.5924 19.3675 1.36785 17.7143 1.36785H4.85714C4.61605 1.36785 4.5 1.1973 4.5 1.08847C4.5 0.979631 4.61605 0.809082 4.85714 0.809082H17.7143C19.8497 0.809082 21.5 2.37473 21.5 4.20601V18.2349C21.5 20.0662 19.8497 21.6319 17.7143 21.6319H4.85714C4.61605 21.6319 4.5 21.4613 4.5 21.3525Z" fill="#FDFEFF" stroke="#787878"/>
        <path d="M13.2739 12.2286L10.2036 15.0317C9.92511 15.2865 9.92511 15.7046 10.2036 15.9594C10.4821 16.2142 10.939 16.2142 11.2175 15.9594L15.5017 12.0391C15.7801 11.7843 15.7801 11.3662 15.5017 11.1114L11.2175 7.19111C10.939 6.9363 10.4821 6.9363 10.2036 7.19111C9.92511 7.44593 9.92511 7.86404 10.2036 8.11886L13.2739 10.9219H0.714035C0.321316 10.9219 0 11.2159 0 11.5753C0 11.9346 0.321316 12.2286 0.714035 12.2286H13.2739Z" fill="#787878"/>
        </svg>
    )
}

export const PurseToPersonType12Icon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 9.25H2C1.59 9.25 1.25 8.91 1.25 8.5C1.25 8.09 1.59 7.75 2 7.75H22C22.41 7.75 22.75 8.09 22.75 8.5C22.75 8.91 22.41 9.25 22 9.25Z" fill="black"/>
            <path d="M8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z" fill="black"/>
            <path d="M14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z" fill="black"/>
            <path d="M17.56 21.25H6.44C2.46 21.25 1.25 20.05 1.25 16.11V7.89C1.25 3.95 2.46 2.75 6.44 2.75H17.55C21.53 2.75 22.74 3.95 22.74 7.89V16.1C22.75 20.05 21.54 21.25 17.56 21.25ZM6.44 4.25C3.3 4.25 2.75 4.79 2.75 7.89V16.1C2.75 19.2 3.3 19.74 6.44 19.74H17.55C20.69 19.74 21.24 19.2 21.24 16.1V7.89C21.24 4.79 20.69 4.25 17.55 4.25H6.44Z" fill="black"/>
        </svg>
    )
}
export const PurseFromPersonType12Icon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 11.2256H2C1.59 11.2256 1.25 10.8878 1.25 10.4804C1.25 10.073 1.59 9.73511 2 9.73511H22C22.41 9.73511 22.75 10.073 22.75 10.4804C22.75 10.8878 22.41 11.2256 22 11.2256Z" fill="black"/>
            <path d="M11.5502 21.6596H6.44025C2.46025 21.6596 1.24023 20.4671 1.24023 16.552V8.38379C1.24023 5.25365 1.91022 3.51469 5.48022 3.30601C5.78022 3.29607 6.10025 3.28613 6.44025 3.28613H17.5502C21.5302 3.28613 22.7502 4.47857 22.7502 8.39372V12.7759C22.7502 13.1833 22.4102 13.5212 22.0002 13.5212C21.5902 13.5212 21.2502 13.1833 21.2502 12.7759V8.39372C21.2502 5.32321 20.7002 4.77668 17.5502 4.77668H6.44025C6.12025 4.77668 5.83023 4.78662 5.55023 4.79655C3.38023 4.92573 2.74023 5.41264 2.74023 8.39372V16.5619C2.74023 19.6324 3.29025 20.1789 6.44025 20.1789H11.5502C11.9602 20.1789 12.3002 20.5168 12.3002 20.9242C12.3002 21.3316 11.9702 21.6596 11.5502 21.6596Z" fill="black"/>
            <path d="M10 17.1878H6C5.59 17.1878 5.25 16.85 5.25 16.4425C5.25 16.0351 5.59 15.6973 6 15.6973H10C10.41 15.6973 10.75 16.0351 10.75 16.4425C10.75 16.85 10.41 17.1878 10 17.1878Z" fill="black"/>
            <path d="M18 23.1501C16.34 23.1501 14.78 22.2756 13.94 20.8546C13.49 20.1392 13.25 19.2945 13.25 18.43C13.25 15.8265 15.38 13.71 18 13.71C20.62 13.71 22.75 15.8265 22.75 18.43C22.75 19.2945 22.51 20.1392 22.06 20.8646C21.22 22.2756 19.66 23.1501 18 23.1501ZM18 15.2005C16.21 15.2005 14.75 16.6513 14.75 18.43C14.75 19.0163 14.91 19.5926 15.22 20.0895C15.8 21.0633 16.87 21.6595 18 21.6595C19.13 21.6595 20.2 21.0633 20.78 20.0994C21.09 19.5926 21.25 19.0262 21.25 18.43C21.25 16.6513 19.79 15.2005 18 15.2005Z" fill="black"/>
            <path d="M17.4299 20.1591C17.2399 20.1591 17.0499 20.0896 16.8999 19.9405L15.9099 18.9567C15.6199 18.6686 15.6199 18.1916 15.9099 17.9034C16.1999 17.6152 16.6799 17.6152 16.9699 17.9034L17.4499 18.3804L19.0499 16.9097C19.3499 16.6315 19.8299 16.6514 20.1099 16.9495C20.3899 17.2476 20.3699 17.7246 20.0699 18.0028L17.9399 19.9604C17.7899 20.0895 17.6099 20.1591 17.4299 20.1591Z" fill="black"/>
        </svg>        
    )
}

export const PurseToPersonType34Icon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.74 22.6329H6.26C3.77 22.6329 1.75 20.6256 1.75 18.1513V11.4638C1.75 8.98945 3.77 6.98218 6.26 6.98218H17.74C20.23 6.98218 22.25 8.98945 22.25 11.4638V12.8947C22.25 13.3021 21.91 13.6399 21.5 13.6399H19.48C19.13 13.6399 18.81 13.7691 18.58 14.0076L18.57 14.0176C18.29 14.2859 18.16 14.6535 18.19 15.0311C18.25 15.6869 18.88 16.2136 19.6 16.2136H21.5C21.91 16.2136 22.25 16.5514 22.25 16.9589V18.1414C22.25 20.6256 20.23 22.6329 17.74 22.6329ZM6.26 8.47272C4.6 8.47272 3.25 9.81422 3.25 11.4638V18.1513C3.25 19.8008 4.6 21.1423 6.26 21.1423H17.74C19.4 21.1423 20.75 19.8008 20.75 18.1513V17.7141H19.6C18.09 17.7141 16.81 16.6011 16.69 15.1702C16.61 14.3554 16.91 13.5505 17.51 12.9642C18.03 12.4376 18.73 12.1494 19.48 12.1494H20.75V11.4638C20.75 9.81422 19.4 8.47272 17.74 8.47272H6.26V8.47272Z" fill="black"/>
            <path d="M2.5 13.1035C2.09 13.1035 1.75 12.7657 1.75 12.3582V7.81712C1.75 6.33651 2.69 4.99497 4.08 4.46831L12.02 1.48722C12.84 1.17918 13.75 1.28853 14.46 1.78538C15.18 2.28223 15.6 3.08711 15.6 3.95163V7.72766C15.6 8.13507 15.26 8.47293 14.85 8.47293C14.44 8.47293 14.1 8.13507 14.1 7.72766V3.95163C14.1 3.57402 13.92 3.22622 13.6 3.00761C13.28 2.78899 12.9 2.7393 12.54 2.87842L4.6 5.85951C3.79 6.16755 3.24 6.9526 3.24 7.81712V12.3582C3.25 12.7756 2.91 13.1035 2.5 13.1035Z" fill="black"/>
            <path d="M19.6005 17.7141C18.0905 17.7141 16.8105 16.6011 16.6905 15.1702C16.6105 14.3455 16.9105 13.5406 17.5105 12.9543C18.0205 12.4376 18.7205 12.1494 19.4705 12.1494H21.5505C22.5405 12.1792 23.3005 12.9543 23.3005 13.9082V15.9553C23.3005 16.9092 22.5405 17.6843 21.5805 17.7141H19.6005ZM21.5305 13.64H19.4805C19.1305 13.64 18.8105 13.7691 18.5805 14.0076C18.2905 14.2859 18.1505 14.6634 18.1905 15.041C18.2505 15.6969 18.8805 16.2235 19.6005 16.2235H21.5605C21.6905 16.2235 21.8105 16.1043 21.8105 15.9553V13.9082C21.8105 13.7592 21.6905 13.6499 21.5305 13.64Z" fill="black"/>
            <path d="M14 12.6959H7C6.59 12.6959 6.25 12.358 6.25 11.9506C6.25 11.5432 6.59 11.2053 7 11.2053H14C14.41 11.2053 14.75 11.5432 14.75 11.9506C14.75 12.358 14.41 12.6959 14 12.6959Z" fill="black"/>
        </svg>
    )
}

export const ReceiptIcon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5396 21.94C10.3096 21.94 10.0895 21.84 9.93954 21.64L8.92953 20.29C8.71953 20.01 8.43956 19.85 8.13956 19.83C7.82956 19.81 7.53953 19.95 7.29953 20.2C5.84953 21.75 4.74954 21.62 4.21954 21.42C3.67954 21.21 2.76953 20.52 2.76953 18.3V7.04001C2.76953 2.60001 4.04953 1.25 8.23953 1.25H15.7896C19.9796 1.25 21.2596 2.60001 21.2596 7.04001V11.3C21.2596 11.71 20.9196 12.05 20.5096 12.05C20.0996 12.05 19.7596 11.71 19.7596 11.3V7.04001C19.7596 3.43001 19.1296 2.75 15.7896 2.75H8.23953C4.89953 2.75 4.26953 3.43001 4.26953 7.04001V18.3C4.26953 19.35 4.52953 19.93 4.76953 20.02C4.94953 20.09 5.43955 19.99 6.19955 19.18C6.74955 18.6 7.46954 18.29 8.21954 18.33C8.95954 18.37 9.65955 18.76 10.1295 19.39L11.1495 20.74C11.3995 21.07 11.3295 21.54 10.9995 21.79C10.8495 21.9 10.6896 21.94 10.5396 21.94Z" fill="#373E41"/>
            <path d="M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z" fill="#373E41"/>
            <path d="M15 11.75H9C8.59 11.75 8.25 11.41 8.25 11C8.25 10.59 8.59 10.25 9 10.25H15C15.41 10.25 15.75 10.59 15.75 11C15.75 11.41 15.41 11.75 15 11.75Z" fill="#373E41"/>
            <path d="M14.8196 21.7801C14.4396 21.7801 14.0796 21.6401 13.8196 21.3801C13.5096 21.0701 13.3696 20.6201 13.4396 20.1501L13.6296 18.8001C13.6796 18.4501 13.8896 18.0301 14.1396 17.7801L17.6796 14.2401C18.1596 13.7601 18.6296 13.5101 19.1396 13.4601C19.7596 13.4001 20.3796 13.6601 20.9596 14.2401C21.5396 14.8201 21.7996 15.4301 21.7396 16.0601C21.6896 16.5601 21.4296 17.0401 20.9596 17.5201L17.4196 21.0601C17.1696 21.3101 16.7496 21.5201 16.3996 21.5701L15.0495 21.7601C14.9695 21.7701 14.8996 21.7801 14.8196 21.7801ZM19.3096 14.9501C19.2996 14.9501 19.2896 14.9501 19.2796 14.9501C19.1396 14.9601 18.9496 15.0901 18.7396 15.3001L15.1996 18.8401C15.1696 18.8701 15.1196 18.9701 15.1196 19.0101L14.9396 20.2601L16.1896 20.0801C16.2296 20.0701 16.3295 20.0201 16.3595 19.9901L19.8996 16.4501C20.1096 16.2301 20.2396 16.0501 20.2496 15.9101C20.2696 15.7101 20.0696 15.4701 19.8996 15.3001C19.7396 15.1401 19.5096 14.9501 19.3096 14.9501Z" fill="#373E41"/>
            <path d="M19.9206 18.2499C19.8506 18.2499 19.7806 18.2399 19.7206 18.2199C18.4006 17.8499 17.3506 16.7999 16.9806 15.4799C16.8706 15.0799 17.1006 14.6699 17.5006 14.5499C17.9006 14.4399 18.3106 14.6699 18.4206 15.0699C18.6506 15.8899 19.3006 16.5399 20.1206 16.7699C20.5206 16.8799 20.7506 17.2999 20.6406 17.6999C20.5506 18.0299 20.2506 18.2499 19.9206 18.2499Z" fill="#373E41"/>
        </svg>
    )
}

export const ArrowSelecterIcon = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.76425 0.235693L0.902319 3.09762C0.772144 3.2278 0.772144 3.43885 0.902319 3.56903L1.37124 4.03795C1.50129 4.168 1.71209 4.16814 1.84232 4.03828L3.99995 1.88666L6.15758 4.03828C6.28781 4.16814 6.49861 4.168 6.62866 4.03795L7.09758 3.56903C7.22776 3.43885 7.22776 3.2278 7.09758 3.09762L4.23565 0.235693C4.10548 0.105518 3.89442 0.105518 3.76425 0.235693Z" fill="#181C20"/>
        </svg>
    )
}

export const ButtonFade = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path d="M0 3C0 1.34315 1.34315 0 3 0H29C30.6569 0 32 1.34315 32 3V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V3Z" fill="#8A99A8" fillOpacity="0.2"/>
        <path d="M12.9613 15.7054L16.5387 12.128C16.7014 11.9652 16.9652 11.9652 17.128 12.128L17.7141 12.7141C17.8767 12.8767 17.8769 13.1402 17.7145 13.303L15.025 16L17.7145 18.697C17.8769 18.8598 17.8767 19.1233 17.7141 19.2859L17.128 19.872C16.9652 20.0348 16.7014 20.0348 16.5387 19.872L12.9613 16.2946C12.7986 16.1319 12.7986 15.8681 12.9613 15.7054Z" fill="black" fillOpacity="0.9"/>
        </g>
        </svg>
              
    )
}
export const ButtonBright = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3C0 1.34315 1.34315 0 3 0H29C30.6569 0 32 1.34315 32 3V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V3Z" fill="#8A99A8" fillOpacity="0.2"/>
        <path d="M19.0387 15.7054L15.4613 12.128C15.2986 11.9652 15.0348 11.9652 14.872 12.128L14.2859 12.7141C14.1233 12.8767 14.1231 13.1402 14.2855 13.303L16.975 16L14.2855 18.697C14.1231 18.8598 14.1233 19.1233 14.2859 19.2859L14.872 19.872C15.0348 20.0348 15.2986 20.0348 15.4613 19.872L19.0387 16.2946C19.2014 16.1319 19.2014 15.8681 19.0387 15.7054Z" fill="black" fillOpacity="0.9"/>
        </svg>        
    )
}