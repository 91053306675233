import React, { useEffect, useState } from "react";
import  Loader  from 'react-loader-spinner'
import { useSelector } from "react-redux"
import { fetchingData } from "../../Fetch";
import { ArrowSelecterIcon } from "../../Icons/icons";
import './styles.sass'

export const ProjectsContainer = () => {

    const headers = ["Overview", "FB Auditory", "FB Posts", "Reports", "Hypotesis", "Label"]
    const [chosenHeader, setChosenHeader] = useState(0)
    const [chosenTitle, setChosenTitle] = useState(0)


    const [tab, setTab] = useState([0,1])

    function hendleTabAdjusting(index){
        if(tab.includes(index)) {
            setTab( tab.slice(0, tab.indexOf(index)).concat(tab.slice(tab.indexOf(index) + 1)) )
            }else{
                setTab([...tab, index])    
            }
        }

    const [projectName, setProjectName] = useState({
           myProjects: [],
           activeProjects: [],
           closedProjects: []
        },
    )


    const token = useSelector(state => state.accessTokenReducer.token)

    useEffect(() => {
        const fetchingName = async(url) => {
            try{     
                const REQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }
                    
                const resName = fetchingData(url, REQUESTSOPTIONS)
                resName.then(data => {
                    setProjectName(data)})
            }catch(err){
                console.log(err);
            }
        }
        if(token){
            fetchingName(process.env.REACT_APP_PROJECTS)
        }
    },[token])
    
    const projects = [
        {appellation: 'My projects',     data:  projectName.myProjects     },
        {appellation: 'Active projects', data:  projectName.activeProjects },
        {appellation: 'Closed projects', data:  projectName.closedProjects },
    ]

        return(
            <div 
                className="projects-container-wrapper"
            >
                
            <div className="projects-container-wrapper__navbar-wrapper">
                {projects.map((elem, index) => {
                    return(
                        <div key={index}
                        className={ tab.includes(index) ?
                            "projects-container-wrapper__navbar-wrapper__appellation-wrapper opening"
                            :
                            "projects-container-wrapper__navbar-wrapper__appellation-wrapper"}
                            >
                            <div className={ tab.includes(index) ?
                                "projects-container-wrapper__navbar-wrapper__appellation-wrapper__title opening-title"
                                :
                                "projects-container-wrapper__navbar-wrapper__appellation-wrapper__title"}
                                onClick={() => hendleTabAdjusting(index)}>

                                {elem.appellation}
                                <div className={ tab.includes(index) ?
                                    "projects-container-wrapper__navbar-wrapper__appellation-wrapper__title__arrow down"
                                    :
                                    "projects-container-wrapper__navbar-wrapper__appellation-wrapper__title__arrow "}>
                                    <ArrowSelecterIcon/>
                                </div>
                            </div>

                            <div className="projects-container-wrapper__navbar-wrapper__appellation-wrapper__appellation-data">
                                {elem.data.length === 0 
                                ?
                                <div style={{paddingLeft:"1vw"}}>
                                    <Loader type='Rings' color="#4169E1"/>
                                </div>
                                :
                                elem.data.map((elem, subTypeIndex) => {
                                    return(
                                        <div key={subTypeIndex}
                                        className={ chosenTitle === subTypeIndex ?
                                            "projects-container-wrapper__navbar-wrapper__appellation-wrapper__appellation-data__title-wrapper chose"
                                            :
                                            "projects-container-wrapper__navbar-wrapper__appellation-wrapper__appellation-data__title-wrapper"}
                                        onClick={() => setChosenTitle(subTypeIndex)}
                                        >
                                            <div
                                            className="projects-container-wrapper__navbar-wrapper__appellation-wrapper__appellation-data__title-wrapper__title">
                                                {elem.title}
                                            </div>
                                        </div> 
                                    )
                                })}
                            </div>                            
                        </div>
                    )
                })}
            </div>
            
            <div className="projects-container-wrapper__data-wrapper">
                <div className="projects-container-wrapper__data-wrapper__header">
                    {headers.map((elem, headerIndex) => {
                        return(
                            <div key={headerIndex}
                                className={chosenHeader === headerIndex ?
                                    "projects-container-wrapper__data-wrapper__header__title chosenHeader"
                                    :
                                    "projects-container-wrapper__data-wrapper__header__title"}
                                onClick={() => setChosenHeader(headerIndex)}
                            >
                                {elem}
                            </div>
                        )
                    })}
                </div>

                <div className="projects-container-wrapper__data-wrapper__data">

                </div>
            </div>
        </div>
        )
}