import React, {useState, useEffect} from 'react'
import { ArrowSelecterIcon } from '../../Icons/icons'
import { fetchingData } from '../../Fetch'
import { useDispatch, useSelector } from 'react-redux'
import './styles.sass'

export const FacebookSearchNavbar = () => {
    
    const dispatch = useDispatch()
    
    //Current position
    const currentPositionCheckedMark = useSelector(state => state.currentPositionReducer.currentPosition)

    // Chosen Companies, its ID and TITLE
    const IdComp = useSelector(state => state.companyIdReducer.IdComp)
    const TitleComp = useSelector(state => state.companyIdReducer.TitleComp)
    
    // Chosen Jobs, its ID and TITLE
    const IdJob = useSelector(state => state.jobIdReducer.IdJob)
    const TitleJob = useSelector(state => state.jobIdReducer.TitleJob)
    
    //Tabs list 
    const options = [
        { name:"JOB TITLES", placeholder: 'Select jobs' },
        { name:"COMPANIES", placeholder: 'Select companies' },
        { name:"TARGET AUDIENCE FOR THE PROJECT", placeholder: 'Select list'}
    ]
    
    //Render necessary companies or jobs ids and titles
    const commonChoose = [
        {title: TitleJob , id: IdJob},
        {title: TitleComp, id: IdComp},
        {title: TitleComp, id: IdComp} 
    ]

    // Adding necessary jobs ids and titles
    function addJobIdAndTitle(newId, newTitle){
        if(IdJob.includes(newId)){
            dispatch({type:"DELETE_JOB_ID", index: IdJob.indexOf(newId)})
            dispatch({type:"SET_CHANGING_FLAG_TRUE"})
        }else{
            dispatch({type:"ADD_JOB_ID", id: newId,  title: newTitle}) 
            dispatch({type:"SET_CHANGING_FLAG_TRUE"}) 
        }
    }
    
    // Adding necessary companies ids and titles
    function addCompanyIdAndTitle(newId, newTitle){
        if(IdComp.includes(newId)){
            dispatch({type:"DELETE_COMPANY_ID", index: IdComp.indexOf(newId)})
            dispatch({type:"SET_CHANGING_FLAG_TRUE"})
        }else{
            dispatch({type:"ADD_COMPANY_ID", id: newId,  title: newTitle}) 
            dispatch({type:"SET_CHANGING_FLAG_TRUE"}) 
        }
    }

    //index using tab
    const [tab, setTab] = useState()    
    
    //input value 
    const [searchJob, setSearchJob] = useState('')
    const [searchCompanies, setSearchCompanies] = useState('')

    //Cause opening absolute white panel with jobs or companies 
    const commonSearching = [
        { searching: searchJob },
        { searching: searchCompanies },
        { searching: searchCompanies }
    ]
    
    // fetch all jobs and companies 
    const [jobs, setJobs] = useState([])
    const [companies, setCompanies] = useState([])
    
    //render them
    const generalization = [
        { name: jobs },
        { name: companies },
        { name: companies }
    ]
    
    const fetchingJobs = async(url) => {
        const resJobs = fetchingData(url)
        resJobs.then(data => setJobs( data.splice(0, 120) ))
    }
    
    const fetchingCompanies = async(url) => {
        setTimeout(() => {
            const resComp = fetchingData(url)
            resComp.then(data => setCompanies( data.splice(0, 120)  ))
        }, 1000)
    }
    
    useEffect(() => {
        if(searchJob.length === 0){fetchingJobs(process.env.REACT_APP_JOBS)}
        else{fetchingJobs(process.env.REACT_APP_JOBSSEARCH + searchJob + `&onlyCurrent=${currentPositionCheckedMark}`)}
    },[currentPositionCheckedMark, searchJob])
    
    useEffect(() => {
        if(searchCompanies.length === 0){fetchingJobs(process.env.REACT_APP_COMPANIES)}
        else{fetchingCompanies(process.env.REACT_APP_COMPANIESSEARCH + searchCompanies + `&onlyCurrent=${currentPositionCheckedMark}`)}    
    },[currentPositionCheckedMark, searchCompanies])

    document.addEventListener("click", e => {
        if(e.target.id !== 'input'){
            setSearchCompanies("")
            setSearchJob("")
        }
    })
    
    return(
        <div 
            className='facebook-search-navbar-wrapper'
        >

            {/* Current position */}
            <div 
                className='facebook-search-navbar-wrapper__current-position'
            >
               <div className={ currentPositionCheckedMark ? 
                    'facebook-search-navbar-wrapper__current-position__checkbox checked'
                    :
                    'facebook-search-navbar-wrapper__current-position__checkbox'
                }
                onClick={() => currentPositionCheckedMark ? 
                    dispatch({type:"CURRENT_POSITION_FALSE"}) 
                    &&
                    dispatch({type:"SET_CHANGING_FLAG_TRUE"}) 
                    :  
                    dispatch({type:"CURRENT_POSITION_TRUE"}) 
                    &&
                    dispatch({type:"SET_CHANGING_FLAG_TRUE"}) 
                }
                    >

               </div>

               <div className='facebook-search-navbar-wrapper__current-position__title'>
                   Current position
                </div>


            </div>
            

            {/*Tabs name and input */}
            {options.map((elem, index) => {

                return(
                    <div key={index} 
                        className={tab === index ? 
                            'facebook-search-navbar-wrapper__tabs-wrapper opening'
                            :
                            'facebook-search-navbar-wrapper__tabs-wrapper'} 
                        onClick={() => setTab(index)}
                    >
                        <div 
                            className='facebook-search-navbar-wrapper__tabs-wrapper__title-field'
                        >
                            <div className='facebook-search-navbar-wrapper__tabs-wrapper__title-field__title'>
                                {elem.name}
                            </div>

                            <div className={ tab === index ?
                                'facebook-search-navbar-wrapper__tabs-wrapper__arrow up'
                                    : 
                                'facebook-search-navbar-wrapper__tabs-wrapper__arrow'} 
                                >
                                <ArrowSelecterIcon/>
                            </div>
                        </div>
                        
                        <div 
                            className='facebook-search-navbar-wrapper__tabs-wrapper__input-field'
                        >
                            <input type="text" placeholder={elem.placeholder} id="input"
                            value={tab === 0 ? searchJob : tab === 1 ? searchCompanies : 'null'}
                            onChange={ e => 
                                    // lengthShift
                                    (tab === 0 ? setSearchJob(e.target.value) 
                                        : 
                                    tab === 1 ? setSearchCompanies(e.target.value) 
                                        :
                                    "null")
                                }
                            />

                            {/* if you write in input list shows */}
                           { commonSearching[index].searching.length !== 0
                                && 
                            tab === index
                                &&                    
                            <div className={
                                tab === 0 ? 'facebook-search-navbar-wrapper__tabs-wrapper__list job'
                                    :
                                tab === 1 ? 'facebook-search-navbar-wrapper__tabs-wrapper__list' : ""}
                            >
                                {
                                    tab === index 
                                        &&
                                    generalization[index].name.length === 0 ? 
                                    <div className='facebook-search-navbar-wrapper__tabs-wrapper__list__strings'>
                                        Nothing
                                    </div>
                                        :
                                    generalization[index].name.map((elem, indexOfStrings) => {
                                        return( 
                                            <div key={indexOfStrings} 
                                                onClick={() => tab === 0 ?
                                                    addJobIdAndTitle(elem.id, elem.title)                                            
                                                        :
                                                    addCompanyIdAndTitle(elem.id, elem.companyTitle)                                                                                            
                                                }
                                                className='facebook-search-navbar-wrapper__tabs-wrapper__list__strings'
                                            >
                                                <div className={  
                                                    commonChoose[index].id.includes(elem.id) 
                                                    ? 
                                                    'facebook-search-navbar-wrapper__tabs-wrapper__list__strings__checkbox checked'
                                                    :
                                                    'facebook-search-navbar-wrapper__tabs-wrapper__list__strings__checkbox'}                                      
                                                    >
                                                </div>
                                                <div className='facebook-search-navbar-wrapper__tabs-wrapper__list__strings__title'>
                                                    {elem.title || elem.companyTitle}
                                                </div>
                                            </div>
                                        )
                                    })    
                                }          
                            </div>
                            }
                        </div>

                        <div className='facebook-search-navbar-wrapper__tabs-wrapper__chose-titles'>
                          {commonChoose[index].title.map((title, choseIndex) =>{
                                return(
                                    <div key={choseIndex} className='facebook-search-navbar-wrapper__tabs-wrapper__chose-titles__line'>

                                        <div onClick={() => tab === 0 ? 
                                                    dispatch({type:"DELETE_JOB_ID", index: TitleJob.indexOf(title)})
                                                    &&
                                                    dispatch({type:"SET_CHANGING_FLAG_TRUE"})
                                                : tab === 1 ? 
                                                    dispatch({type:"DELETE_COMPANY_ID", index: TitleComp.indexOf(title)}) 
                                                    &&
                                                    dispatch({type:"SET_CHANGING_FLAG_TRUE"})     
                                                : "" }  
                                            className='facebook-search-navbar-wrapper__tabs-wrapper__chose-titles__line__checkbox'></div>
                                        <div className='facebook-search-navbar-wrapper__tabs-wrapper__chose-titles__line__tilte'>
                                            {title}
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                )
            })}

        </div>
    )
} 
