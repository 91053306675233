import React,{useState, useEffect} from 'react'
import { ConfirmationOfPayment } from '../../Components/ConfirmationOfPayment'
import { ProfilePayments } from '../../Components/ProfilePayments'
import './styles.sass'

export const ProfileContainer = () => {

    const [chose, setChose] = useState(0)

    useEffect(() => {
        setChose(JSON.parse(window.sessionStorage.getItem('chose')));
    }, []);
    
    useEffect(() => {
            window.sessionStorage.setItem('chose', chose)
    }, [chose])
    
    if(chose === null){
        setChose(0)
    }
        
    const profilePages = [
        {name: 'Profile', data: ''},
        {name: 'Payments', data: <ProfilePayments/>},
        {name: 'Payment Info', data: ''},
        {name: 'Confirmation of payment', data: <ConfirmationOfPayment/>},
    ]

    return(
        <div className='profile-container-wrapper'>

            <div className='profile-container-wrapper__title'>
                Profile
            </div>

            <div className='profile-container-wrapper__pages'>
                {profilePages.map((elem,index) => {
                    return(
                            <div key={index} 
                            className={chose === index 
                                ? 
                            "profile-container-wrapper__pages__name chose" 
                                :
                            "profile-container-wrapper__pages__name"}
                            onClick={() => setChose(index)} >
                                {elem.name}
                            </div>
                    )
                })}
            </div>

            <div className='profile-container-wrapper__data'>
                { chose !== null && 
                profilePages[chose].data}
            </div>
            
        </div>
    )
}