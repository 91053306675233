const defaultFlag = {
    flag: false
}

export const arrayChangingFlagReducer = (state = defaultFlag, action ) => {
    switch(action.type){
        case "SET_CHANGING_FLAG_TRUE": 
            return{...state, flag: true}
        case "SET_CHANGING_FLAG_FALSE": 
            return{...state, flag: false}
        default: return state
    }
}