const defaultPage = {
    pageNum : 1
}


export const pagesReducer = (state = defaultPage, action ) => {
    switch(action.type) {
        case "INCREMENT_PAGE" :
            return {...state, pageNum :  state.pageNum + 1 }
        case "DECREMENT_PAGE" :
            return {
                ...state, pageNum :  state.pageNum - 1 }
        case "DEFAULT_PAGE":
            return{ ...state , pageNum : 1}
        default:
            return state
    }
}