import React,{ useEffect, useState } from "react"
import { PurseToPersonType12Icon, PurseFromPersonType12Icon,
    PurseToPersonType34Icon, ReceiptIcon } from '../../Icons/icons'
import { LoaderWindow } from '../../Components/LoaderWindow'
import { useSelector } from "react-redux"
import { fetchingData } from '../../Fetch'
import './styles.sass'

export const VerifyReceipts = () => {

    const [result, setResult] = useState([])

    const token = useSelector(state => state.accessTokenReducer.token)
    
    useEffect(() => {
        const fetchingReceipts = async(url) => {
            try{
                const REQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = fetchingData(url, REQUESTSOPTIONS)
                res.then(data => setResult(data))
                
                // res.then(data => setResult(data))
            }catch(err){
                console.log(err);
            }
        }
        if(token){
            fetchingReceipts(process.env.REACT_APP_VERIFYRECEIPTS)
        }
    },[token])
    
    const headers = ["TO", "FROM","PAYMENT PERIOD", "AMOUNT", "RECEIPT", "ACTIONS"]
    
    return(
            <div 
                className='verify-receipts-wrapper'
            >
                <div className='verify-receipts-wrapper__table-head'>
                    {headers.map((elem, index) => {
                        return(
                            <div key={index} 
                            className='verify-receipts-wrapper__table-head__title'>
                                {elem}
                            </div>
                        )
                    })}
                </div>

                <div className='verify-receipts-wrapper__table-wrapper'>
                    { result.length === 0 ?
                    <div style={{position:'absolute', width:'100%', height:'100%'}}>
                        <LoaderWindow/>
                    </div>
                        :
                    result.map((elem, resIndex) => {
                        return(
                            <div key={resIndex}
                                className='verify-receipts-wrapper__table-wrapper__strings'
                            >
                                <div className='verify-receipts-wrapper__table-wrapper__strings__to'>
                                    
                                   {elem.paymentAccountFrom.type === ( 1 || 2 ) ? 
                                        <div  className='verify-receipts-wrapper__table-wrapper__strings__button'>
                                            <PurseFromPersonType12Icon/> 
                                        </div>
                                            :
                                        <div  className='verify-receipts-wrapper__table-wrapper__strings__button'>
                                            <PurseToPersonType34Icon/>
                                        </div>
                                    }
                                    <div className='verify-receipts-wrapper__table-wrapper__strings__title'>
                                        {elem.paymentAccountTo.title}
                                   </div>
                                </div>

                                <div className='verify-receipts-wrapper__table-wrapper__strings__from'>
                                {elem.paymentAccountFrom.type === ( 1 || 2 ) ? 
                                        <div  className='verify-receipts-wrapper__table-wrapper__strings__button'>
                                        <PurseToPersonType12Icon/>  
                                        </div>
                                            :
                                        <div  className='verify-receipts-wrapper__table-wrapper__strings__button'>
                                            <PurseToPersonType34Icon/>
                                        </div>
                                        } 
                                    <div className='verify-receipts-wrapper__table-wrapper__strings__title'>
                                        {elem.paymentAccountFrom.title}
                                   </div>
                                </div>

                                <div className='verify-receipts-wrapper__table-wrapper__strings__period'>
                                    <div className='verify-receipts-wrapper__table-wrapper__strings__title'>
                                        {elem.paymentPeriod.title}
                                    </div>
                                </div>

                                <div className='verify-receipts-wrapper__table-wrapper__strings__amount'>
                                    <div className='verify-receipts-wrapper__table-wrapper__strings__title'>
                                        {elem.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}

                                    </div>
                                </div>

                                <div className='verify-receipts-wrapper__table-wrapper__strings__receipt'>
                                    {elem.receiptUrl 
                                        &&
                                        <div style={{width: '3.9vmin'}}>
                                            <a href={elem.receiptUrl}>
                                                <ReceiptIcon/>
                                            </a>
                                        </div>
                                    }
                                    
                                </div>

                                <div className='verify-receipts-wrapper__table-wrapper__strings__actions'>
                                    <div onClick={() => console.log('click')}>
                                        <div className='verify-receipts-wrapper__table-wrapper__strings__actions__title'>
                                            Confirm
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
    )
}