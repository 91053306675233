import { useEffect, useState } from 'react'
import { Routes, Route  } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileViewer } from './Viewers/Profile'
import { FacebookSearchViewer } from './Viewers/FacebookSearch'
import { FinancesViewer } from './Viewers/Finances'
import { Navbar } from './Components/Navbar/index'
import { ProjectsViewer } from './Viewers/Projects'
import { LoaderWindow } from './Components/LoaderWindow'
import './App.sass'
import { fetchingData } from './Fetch'

function App() {


  const permission = useSelector(state => state.permissionReducer.permission)

  //Redux
  const dispatch = useDispatch()
  const loader = useSelector(state => state.loaderReducer.loaderState)
  const token = useSelector(state => state.accessTokenReducer.token)

  //Auth0
  const { 
      isLoading,
      error,
      isAuthenticated,
      loginWithRedirect,
      getAccessTokenSilently } = useAuth0()
      
  //Regustration request
  useEffect(() => {
    if(!isLoading && !isAuthenticated) loginWithRedirect()
  })

  useEffect(() => {
    const fetchPermission = async(url) => {
      const REQUESTSOPTIONS = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
      }
      const res = fetchingData(url, REQUESTSOPTIONS)
      res.then(data => dispatch({type:"SET_PERMISSION", payload: data}))
    }
    
    if(token){
      fetchPermission(process.env.REACT_APP_USER_PERMISSION)
    } 
  },[dispatch, token])
  // console.log(permission, "PERSMISSION");
  
  if(isLoading){
    return   <LoaderWindow/>
  }

  if(error){
    return <div>Oops....{error.message}</div>
  }
  
  //AccessToken for registered peoples
  getAccessTokenSilently().then(token => dispatch({type:'NEW_TOKEN', payload:token}))
  
  return (
    isAuthenticated 
      &&
    <div className='app-wrapper'>

    
    {/* Loader will turn on when component requires ones */}
    { 
     ( loader || 
      Object.keys( permission ).length === 0)
        && 
        <div>
          <LoaderWindow/>   
        </div>
             
    } 

   
        
        <div className='app-navbar'>
          <Navbar />
        </div>

        <div className='app-right-side'>

          <Routes>

            <Route exact path='/' element={''}/>

            <Route exact path='/projects' element={<ProjectsViewer/>}/>
            
            <Route exact path='/finances' element={<FinancesViewer authed={true}/> } />
            
            <Route exact path='/facebook-search' element={<FacebookSearchViewer/>}/>

            <Route exact path='/profile' element={<ProfileViewer/>}/>
          
          </Routes>

        </div>
        
      {/* </Router> */}

    </div>
  )
}

export default App;