const defaultPosition = {
    currentPosition: true
}


export const currentPositionReducer = (state = defaultPosition, action) => {
    switch(action.type){
        case "CURRENT_POSITION_TRUE":
            return {...state, currentPosition: true}
        case "CURRENT_POSITION_FALSE":
            return {...state, currentPosition: false}
        default:
            return state
    }
}