import { useAuth0 } from '@auth0/auth0-react'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { FinancesTabIcon, FacebookSearchTabIcon,
     ProjectsTabIcon, ProfileTabIcon, LogOutTabIcon } from '../../Icons/icons'
import { LoaderWindow } from '../LoaderWindow'

import './styles.sass'

export const Navbar = () => {

    const permission = useSelector(state => state.permissionReducer.permission)
    

    // Auth0
    const { logout, user } = useAuth0()
    
    const [choseNavbar, setChoseNavbar] = useState("")
    
    useEffect(() => {
        setChoseNavbar(JSON.parse(window.sessionStorage.getItem('choseNavbar')));
    }, []);
    
    useEffect(() => {
        window.sessionStorage.setItem('choseNavbar', choseNavbar);
    }, [choseNavbar]);
      
    
        // Tabs Array
    const tabs = [
        {name: 'Projects', to:"/projects", icon:<ProjectsTabIcon/>},
        {name: 'Finances', to:"/finances", icon:<FinancesTabIcon/>},
        {name: 'Facebook Search', to:"/facebook-search", icon:<FacebookSearchTabIcon/>},
        {name: 'Profile', to:"/profile", icon:<ProfileTabIcon/>},
    ]

    return(
        <div 
            className="navbar-wrapper"
        >
           
            <div className="navbar-wrapper__head">
                ModumOps
            </div>


            <div 
                className="navbar-wrapper__body-wrapper"
            >
                
                {tabs.map((elem, index) => {
                
                    return(
                        <div key={index} 
                            className={
                                (index === 1 && (!permission.financesView )) 
                                    || 
                                (index === 2 && (!permission.facebookSearchView )) 
                                    ? 
                                "navbar-wrapper__body-wrapper__tabs-wrapper__hidden "
                                :
                                "navbar-wrapper__body-wrapper__tabs-wrapper"}
                        >
                            <Link to={elem.to} style={{textDecoration: 'none'}} >
                                
                                <div className={ choseNavbar === index ? 
                                    "navbar-wrapper__body-wrapper__tabs-wrapper__tab chose" 
                                        :
                                    "navbar-wrapper__body-wrapper__tabs-wrapper__tab"}
                                    onClick={() => setChoseNavbar(index)}
                                >
            
                                    <div className="navbar-wrapper__body-wrapper__tabs-wrapper__tab__icon">
                                        {elem.icon}
                                    </div>
                                
                                    <div className="navbar-wrapper__body-wrapper__tabs-wrapper__tab__name">
                                        {elem.name}
                                    </div>
                                
                                </div>
                            
                            </Link>

                        </div>
                    )
                })}
                
                {/* Log out */}
                
                <div
                    className="navbar-wrapper__body-wrapper__log-out-wrapper"
                >

                    <img alt='pic' src={user.picture} referrerPolicy="no-referrer" 
                        className="navbar-wrapper__body-wrapper__log-out-wrapper__icon"
                    />
                                  
                    <div className="navbar-wrapper__body-wrapper__log-out-wrapper__name">
                        {user.name}
                    </div>
                    
                    <div className="navbar-wrapper__body-wrapper__log-out-wrapper__button"
                        onClick={() => logout({returnTo: window.location.origin})}>
                        <LogOutTabIcon/>
                    </div>
                
                </div>

            </div>
        </div>
    )
}
