import React,{ useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { fetchingData } from '../../Fetch'
import { LoaderWindow } from '../../Components/LoaderWindow'
import './styles.sass'

export const Confirmation = () => {

    const [result, setResult] = useState([])
    const [confirm, setConfirm] = useState()

    const header = ["PAYMENT PERIOD", "WORKDAYS", "PAYMENT AMOUNT", "PAYOUT INCLUDING TAX"]
    const projectHeader = ["PROJECT", "ROLE", "WORK PERIOD", "RATE", "DETAILS", "INCOME"]

    const token = useSelector(state => state.accessTokenReducer.token)
    const permission = useSelector(state => state.permissionReducer.permission) 
    
    
    
    useEffect(() => {
        const fetchingConfirm = async(url) => {
            try{    
                const GETREQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = fetchingData(url, GETREQUESTSOPTIONS)
                console.log(res);
                res.then(data => setResult(data))    
            }catch(err){
                console.log(err);
            }
        }

        const fetchingConfirmPOST = async(url) => {
            try{    
                const POSTREQUESTSOPTIONS = {
                    method: 'POST',
                    headers: { 
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = fetchingData(url + '/' + confirm, POSTREQUESTSOPTIONS)
                console.log(res);
            }catch(err){
                console.log(err);
            }
        }

        if(token){
            fetchingConfirm(process.env.REACT_APP_COMFIRMATION)
        }
        if(token && confirm){
            console.log("Table went")
            fetchingConfirmPOST(process.env.REACT_APP_COMFIRMATION)
            setResult([])
            fetchingConfirm(process.env.REACT_APP_COMFIRMATION)
        }
    },[confirm, token])

    console.log(confirm);

    return(
            <div 
                className="confirmation-wrapper" 
            >
                { result.length === 0 ?
                <div style={{position:'absolute', width:'102%', height:'100%'}}>
                    <LoaderWindow/>
                </div>
                :
                result.map((elem, index) => {
                    return(
                        <div key={index}
                            className="confirmation-wrapper__table-wrapper"
                        >
                            <div 
                                className="confirmation-wrapper__table-wrapper__header"
                            >
                                <div 
                                    className="confirmation-wrapper__table-wrapper__header__name"
                                >
                            
                                    <img alt='a' src={elem.userProfile.avatarUrl}
                                        className="confirmation-wrapper__table-wrapper__header__name__avatar"
                                    />
                                    <div className="confirmation-wrapper__table-wrapper__header__name__title">
                                        {elem.userProfile.name}
                                    </div>

                                </div>

                                {
                                permission.financesConfirmExecute 
                                        &&
                                <div 
                                    className="confirmation-wrapper__table-wrapper__header__confirm-button"
                                    onClick={() => setConfirm(elem.id)}
                                >
                                    <div className="confirmation-wrapper__table-wrapper__header__confirm-button__title">
                                        Confirm Payment
                                    </div>

                                </div>
                                }

                            </div>

                            <div 
                                className="confirmation-wrapper__table-wrapper__data-wrapper"
                            >
                                <div className="confirmation-wrapper__table-wrapper__data-wrapper__header-wrapper">
                                    {header.map((elem, headerIndex) => {
                                        return(
                                            <div key={headerIndex}
                                                className="confirmation-wrapper__table-wrapper__data-wrapper__header-wrapper__header"
                                            >
                                                <div className="confirmation-wrapper__table-wrapper__data-wrapper__header-wrapper__header__title">
                                                    {elem}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div 
                                    className="confirmation-wrapper__table-wrapper__data-wrapper__data"
                                >
                                    
                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__data__block">
                                        {elem.paymentPeriod.title}
                                    </div>

                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__data__block">
                                        {elem.paymentPeriod.workDays}
                                    </div>
                                    
                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__data__block">
                                        {elem.rawAmount}
                                    </div>

                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__data__block">
                                        {elem.amount}
                                    </div>

                                </div>

                                <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project">
                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__header-wrapper">
                                        {projectHeader.map((elem, projectIndex) => {
                                            return(
                                                <div key={projectIndex}
                                                    className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__header-wrapper__header"
                                                >
                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__header-wrapper__header__title">
                                                        {elem}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div 
                                        className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data"
                                    >  
                                        {elem.projectPaymentDetails.map((details, detailsIndex) => {
                                            return(
                                                <div key={detailsIndex}
                                                    className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string"
                                                >
                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string__block">
                                                        {details.projectTitle}
                                                    </div>

                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string__block">
                                                        {details.projectRole}
                                                    </div>
                                                    
                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string__block">
                                                        {details.startDate.slice(5, 7)}.{details.startDate.slice(8, 10)}-{details.endDate.slice(5, 7)}.{details.endDate.slice(8, 10)}
                                                    </div>
                                                    
                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string__block">
                                                        {details.rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                                    </div>
                                                    
                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string__block">
                                                    
                                                    </div>
                                                    
                                                    <div className="confirmation-wrapper__table-wrapper__data-wrapper__about-project__data__string__block">
                                                        {details.rawAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                                    </div>
                                                </div>

                                            )
                                        })}

                                    </div>

                                </div>

                            </div>

                        </div>
                    )
                })}
            </div>
    )
}