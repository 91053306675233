import React,{ useEffect, useState } from "react"
import { LoaderWindow } from  '../../Components/LoaderWindow'
import { useSelector } from "react-redux"
import { fetchingData } from '../../Fetch'
import './styles.sass'
import { ReceiptIcon } from "../../Icons/icons"

export const AllPayments = () => {

    const [result, setResult] = useState([])

    const headers = ["NAME", "PAYMENT PERIOD", "STATUS", "AMOUNT", "RECEIPT"]

    const token = useSelector(state => state.accessTokenReducer.token)
    const inputs = ["Name", "Period", "Status"]
    
    useEffect(() => {
        const fetchingPayments = async(url) => {
            try{
                const REQUESTSOPTIONS = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }
                const res = fetchingData(url, REQUESTSOPTIONS)
                res.then(data => setResult(data))
                console.log(res);
            }catch(err){
                console.log(err);
            }
        }
        if(token){
            fetchingPayments(process.env.REACT_APP_ALLPAYMENTS)        
        }
    },[token])

console.log(result);
    return(
            <div 
                className="allPayments-wrapper"
            >
                <div 
                    className="allPayments-wrapper__filters"
                >
                    
                    <div className="allPayments-wrapper__filters__title">
                        Filters
                    </div>

                    <div className="allPayments-wrapper__filters__input-wrapper">
                        {inputs.map((elem, index) => {
                            return(
                                <div key={index}
                                    className="allPayments-wrapper__filters__input-wrapper__input"
                                >
                                    <input type="text"  placeholder={elem} />
                                </div>
                                )
                            })}
                    </div>

                </div>
                
                <div className="allPayments-wrapper__table-wrapper">
                    <div className="allPayments-wrapper__table-wrapper__header-wrapper">
                        {headers.map((elem, index) => {
                            return(
                                <div key={index}
                                    className="allPayments-wrapper__table-wrapper__header-wrapper__header"
                                >
                                    {elem}
                                </div>
                            )
                        })}
                    </div>

                    <div className="allPayments-wrapper__table-wrapper__data-wrapper">
                        { result.length === 0 || result === false ?
                        <div style={{position:'absolute', width:'100%', height:'100%'}}>
                            <LoaderWindow/>
                        </div>
                            :
                        result.map((elem, index) => {
                            return(
                                <div key={index}
                                    className="allPayments-wrapper__table-wrapper__data-wrapper__string"
                                >
                                    <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__name">
                                        <img alt='a' src={elem.userProfile.avatarUrl} referrerPolicy="no-referrer"/>
                                        <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__title">
                                            {elem.paymentAccountTo.title}
                                        </div>
                                    </div>

                                    <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__period">
                                        <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__title">
                                            {elem.paymentPeriod.title}
                                        </div>
                                    </div>

                                    <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__status">
                                        <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__title"
                                        style={
                                            elem.paymentStatus.status === 0 ? {background:'#D9F1FF'} 
                                                :
                                            elem.paymentStatus.status === 2 ||  elem.paymentStatus.status === 3 || elem.paymentStatus.status === 5 ? {background:'#FFFBCC'} 
                                                :
                                            elem.paymentStatus.status === 1 || elem.paymentStatus.status === 4 || elem.paymentStatus.status === 6 ? {border:' 3px solid #FFFBCC'} 
                                                : 
                                            elem.paymentStatus.status === 8 ? {border:' 3px solid #D9FAE5'}
                                                : 
                                            elem.paymentStatus.status === 10 ? {border:' 3px solid #FFD6D6'}
                                                : 
                                            {border:' 3px solid red'}
                                            }>
                                            {elem.paymentStatus.title}
                                        </div>
                                    </div>

                                    <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__amount">
                                        <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__title">
                                            {elem.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "’")}
                                        </div>
                                    </div>
                                    
                                    <div className="allPayments-wrapper__table-wrapper__data-wrapper__string__receipt">
                                        {elem.receiptUrl && 
                                        <div style={{width:'1.5vw', paddingTop:'1vh'}}>
                                        <a href={elem.receiptUrl}>
                                            <ReceiptIcon/>    
                                        </a>
                                        </div>
                                        }
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>


            </div>
    )
}