import { combineReducers } from "redux"
import { loaderReducer } from './loaderReducer'
import { accessTokenReducer } from "./accessTokenReducer"
import { currentPositionReducer } from "./currentPositionReducer"
import { companyIdReducer } from "./companyIdReducer"
import { jobIdReducer } from "./jobIdReducer"
import { pagesReducer } from "./pagesReducer"
import { arrayChangingFlagReducer } from "./arrayChangingFlagReducer"
import { clickPageReducer } from "./clickPageReducer"
import { permissionReducer } from './permissionReducer'

export const rootReducer = combineReducers({
    loaderReducer,
    accessTokenReducer, 
    currentPositionReducer,
    companyIdReducer,
    jobIdReducer,
    pagesReducer,
    arrayChangingFlagReducer,
    clickPageReducer,
    permissionReducer
})